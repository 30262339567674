@import "./blockly.css";

.label {
  font-weight: bold;
}

.cursor {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.full-width {
  width: 100%;
}
.capitalize {
  text-transform: capitalize;
}
.spinner {
  z-index: 99999999;
  max-height: none !important;
  color: var(--primary-color) !important;
}

.large-text {
  font-size: 24px;
}

.medium-text {
  font-size: 16px;
}
.upper {
  text-transform: uppercase;
}

.small-text {
  font-size: 14px;
}
.regular-text {
  font-size: 16px;
}

.theme-text {
  color: var(--primary-color) !important;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.height-50 {
  height: 50px !important;
}

.primary-button {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.default-button {
  color: var(--secondary-color) !important;
}

.button {
  border: 1px solid var(--primary-color) !important;
}

.primarybtn{
  background-color: var(--primary-color)!important;
  color: var(--primary-color) !important;
  border-width: 5px;
  font-weight: 600
}

.inputBox-style {
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
}

.error-message {
  text-align: left;
  color: red;
  font-size: 14px;
}
.error-pad {
  padding: 0 0 25px 0;
}
.w-70 {
  width: 70%;
}
.h-100 {
  height: 100%;
}
.b-5{
  border-radius: 5px !important;
}
.drawer-bottom {
  width: 100%;
  padding: 15px 24px;
  border-top: 1px solid #0000001a;
}

.drawer-body {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: max-content auto max-content;
}

.right-body {
  width: 70%;
  height: 100%;
  overflow: auto;
  padding: 5% 20%;
  background-color: #f2f2f2;
}

.w-50 {
  width: 50%;
}
.w-40{
  width:40%
}
.w-60{
  width:60%
}
.padding-20 {
  padding: 20px 0;
}

.width-30 {
  width: 33%;
}

.password-info {
  font-size: 12px;
  padding-bottom: 5px;
  font-weight: 600;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-10 {
  margin: 10px 0px;
}
.margin-2 {
  margin-bottom: 5px !important;
}
.disable-text {
  color: var(--secondary-color);
}

.ellipsis-style {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blocklyWidgetDiv {
  z-index: 999;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.center_div{
  display: flex;
  align-items: center;
  justify-content: center;
}