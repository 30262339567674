#indicator-block {
  flex-direction: column;
}

#indicator-block .indicator-field {
  width: 100%;
  display: flex;
  align-items: center;
}

#indicator-block .indicator-checkbox {
  margin-left: auto;
  padding-right: 10px;
}

#indicator-block .indicator-label {
  margin-right: 1em;
  user-select: none;
  font-weight: 700;
  font-family: var(--font-family-head-);
  font-size: 16px;
}

#indicator-block .indicator-icon {
  align-self: center;
}

#indicator-block .indicator-select {
  width: 100%;
  margin-top: -1em;
}

#indicator-block .indicator-form-item {
  margin: 0;
}

.buy-sell-select .ant-select-selection-item {
  white-space: unset;
  text-overflow: unset;
}
.drawer_btn{
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}
.btn_hover{
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
}