.table_pannel {
    
    padding: 25px;
    background: transparent;
    

}

.search_pannel,
.filter_pannel {
    background: #fff;
}

.filter_pannel{
    overflow: hidden;
    overflow-x: auto
}
.filter-label {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 5px !important;
}

.search_pannel {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid var(--bordercolor);
    padding: 20px;
    margin-bottom: 10px;
    gap: 5%;
}

.sym_select,
.date-pick,
.Sinput {
    width: 220px;
    height: 50px;
    border-color: var(--bordercolor);
    border: 1px solid var(--bordercolor) !important;
}

.searchimg {
    width: 16px;
    height: 17px;
}

.srch_btn {
    width: 150px;
    height: 50px;
    /* position: absolute;
    bottom: 0; */
}

.btn-grp {
    display: flex;
    gap: 15px;
    align-items: flex-end;
}

.pagination {
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: end;
}

.pbtn {
    width: 90px;
    height: 36px;
    gap: 4px;
    border: 2px solid var(--bordercolor);
    background: transparent;
    color: var(--primary-color);
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
}
.page-number {
    /* width: 34px; */
    height: 36px;
    border: 2px solid var(--bordercolor);
    background: transparent;
    color: var(--primary-color);
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;
}



.fbtn img {
    height: 8px;
    width: 8px;
    margin-right: 5px;
    margin-top: -1px;
}
.lbtn img {
    height: 8px;
    width: 8px;
    margin-left: 5px;
    margin-top: -1px;
}
.active-pg{
    background-color: var(--primary-color);
    color: #fff;
    border-color: var(--primary-color);
}

.table_pannel {
    overflow-x: auto;
  }
  
  .table_pannel table {
    width: 100%;
    min-width: 600px;  
  }
  
  .pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    margin: 5px 0;
  }
  
  @media (min-width: 600px) {
    .pagination {
      flex-direction: row;
    }
    .btn-grp{
        margin-top: 15px;
    }
    .pagination button {
      margin: 0 5px;
    }
  }
  
  .react-datetime-picker__inputGroup__input:invalid {
    background: unset !important;
  }
  .react-datetime-picker__wrapper{
    border: unset !important;
    padding: 0 5px !important;
  }