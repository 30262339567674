.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .pagination > .previous,
  .pagination > .next,
  .pagination-button {
    background: none;
    width: 90px;
    height: 36px;
    border: 2px solid var(--bordercolor);
    color: var(--primary-color);
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    cursor: not-allowed;
    color: #aaa;
    border-color: #aaa;
  }
  
  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
   margin-top: 15px;
  }
  
  .pagination > li {
    margin: 0 5px;
  }
  
  .pagination > .previous{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pagination > .next{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pagination > .previous > a,
  .pagination > .next > a{
   border: unset;
  }
  .pagination > li > a {
    text-decoration: none;
    color: var(--primary-color);
    font-weight: bold;
    text-transform: uppercase;
    padding: 9px;
    border: 1px solid;
  }
  
  .pagination > li.active > a {
    background-color: #000;
    color: #fff;
    padding: 9px;
    border: 1px solid;
    
  }
  
  .pagination > li.break-me > a {
    cursor: default;
  }
  