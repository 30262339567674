#alert-block {
  flex-direction: column;
}

#alert-block .alert-field {
  width: 100%;
  display: flex;
}

#alert-block .alert-checkbox {
  margin-left: auto;
  padding-right: 10px;
}

#alert-block .alert-label {
  margin-right: 1em;
  user-select: none;
}

#alert-block .alert-icon {
  align-self: center;
}

#alert-block .alert-select {
  width: 100%;
  margin-top: -1em;
}

#alert-block .alert-form-item {
  margin: 0;
}

.buy-sell-select .ant-select-selection-item {
  white-space: unset;
  text-overflow: unset;
}
