.swipeable {
  display: flex;
  align-content: center;
  justify-content: center;
  height: max-content;
  width: auto;
  padding: 16px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-size {
  font-size: 60px;
  font-weight: 300;
}

.swipeableViews {
  height: 100%;
  padding: 8px;
}
.column {
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.alignFiled {
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
}
.alignCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.align-component {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  padding: 8px;
}
.align-one-comp {
  display: flex;
  justify-content: space-evenly;
}
.textalignRow {
  display: flex;
  flex-direction: row;
}
.textalignColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.imageSet {
  background-size: cover;
}
.mrRight {
  margin-right: 16px;
}
.mrTop {
  margin-top: 16px;
}
.alignnone {
  align-items: unset !important;
}
.imageBelow {
  display: flex;
  flex-direction: column-reverse;
}
.div-pad {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-top: 8px;
}
.cardRatio {
  position: relative !important;
  height: auto;
  width: 100%;
  background-color: white;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.cardRatio:hover {
  transform: scale(1.02);
}
.image-set-back {
  background-size: cover;
  background-repeat: no-repeat;
  /* border-radius: 10px; */
  cursor: pointer;
  transition: transform 0.3s ease;
}
.b-shadow {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.image-size {
  height: 400px;
}
.font {
  font-size: 16px;
}
.max-image-size {
  max-height: 400px;
}
.div-margin {
  margin: 8px;
}
.image-align {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 0rem;
}
.image-set-back:hover {
  transform: scale(1.02);
}
.image-ratio {
  height: 130px;
  width: 130px;
}
.positionImageBelow {
  position: relative;
}
.positionImageAbove {
  position: absolute;
  z-index: 15;
  right: 1em;
  bottom: 1em;
  cursor: pointer;
  padding: 8px;
  background-color: white;
  border-radius: 5px;
}
.marginLeft {
  margin-left: 1rem;
}
.min_width {
  min-width: 350px;
}
.text-dark {
  color: black !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #57387d;
  border-color: #f9f9f9;
}
.bottom_btn {
  position: absolute;
    /* width: 90%; */
    top: 20px;
    right: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 230px;
}

.white_btn {
  background: #f9f9f9 !important;
  font-weight: 600;
  color: black !important;
  border: unset !important;
  width: 32px;
  height: 32px;
  padding: 0px !important;
  border-radius: 5px !important;

}
.white_btn img{
  width: 18px;
  height: 18px;

}
/* .white_btn:hover {
  background: transparent !important;
  color: white !important;
  border: 1px solid #f9f9f9 !important;
} */
.cancel_btn {
  background: red !important;
  font-weight: 600;
  color: white !important;
  border: 1px solid red !important;
}
.cancel_btn:hover {
  background: transparent !important;
  color: red !important;
  border: 1px solid red !important;
}
.t-subrobot{
  font-family:var(--font-family-head-);
font-size: 18px;
font-weight: 700;
line-height: 30px;
letter-spacing: -0.02em;
text-align: left;

}