#bar-block {
  flex-direction: column;
}

#bar-block .bar-field {
  width: 100%;
  display: flex;
}

#bar-block .bar-checkbox {
  margin-left: auto;
  padding-right: 10px;
}

#bar-block .bar-label {
  margin-right: 1em;
  user-select: none;
}

#bar-block .bar-icon {
  align-self: center;
}

#bar-block .bar-select {
  width: 100%;
  margin-top: -1em;
}

#bar-block .bar-form-item {
  margin: 0;
}

.buy-sell-select .ant-select-selection-item {
  white-space: unset;
  text-overflow: unset;
}
