.signup-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  background: #fff;
  position: relative;
  overflow: hidden;
}


.selectBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b1a1c3;
  color: var(--primary-color);
}

.name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.top-body {
  padding: 3% 17% 7% 18%;
}
.signup-form {
  padding: 4% 9%;
}
.signup-form-head {
  width: 90%;
}
.login-box .password-info{
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.04em;
  text-align: left;

}
.d-flex-colum{
  display: flex;
  flex-direction: column;
}
