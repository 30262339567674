/* Override for ant styles */
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #211d7017 !important;
}

.ant-table{
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  height: 45px !important;
}

.ant-select-selector .ant-select-selection-placeholder {
  line-height: 50px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 50px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  -webkit-transition: none !important;
  box-shadow: none !important;
}

.ant-drawer-body{
  padding: 0px !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after, .ant-steps-item-process .ant-steps-item-icon, .ant-spin-dot-item {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-steps-item-finish .ant-steps-item-icon{
  border-color: var(--primary-color) !important;
}

.ant-modal-title{
  color: var(--primary-color) !important;
  font-weight: bold !important;
}

.ant-btn-primary{
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.ant-btn{
  border: 1px solid var(--primary-color)  !important;
  font-weight: 700 !important;
  padding: 0px 15px !important;
  border-radius: unset !important;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon, .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title, .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: var(--primary-color) !important;
}
.ant-btn:hover{
  /* color: var(--secondary-color) !important; */
  border: 1px solid var(--secondary-color)  !important;
}
.ant-btn-default{
  color: var(--primary-color) !important;
}

.ant-drawer-title{
  font-weight: bold !important;
  color: var(--primary-color) !important;
}

.ant-drawer-title{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-picker-focused {
  border-color: var(--primary-color) !important; 
  box-shadow: unset !important;
}
.ant-picker-suffix img{
width: 24px;
height: 24px;
}

.ant-drawer-title {
  font-family: var(--font-family-head-);
}