@font-face {
  font-family: "Novecentosanswide";
  src: url("https://api.robotmaker.ai/static/fonts/Novecentosans-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family:  'proxima-nova', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #404E6A !important;
  overflow-x: hidden;
}

 
::-webkit-scrollbar {
  width: 10px; 
}

 
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

 
::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);  
  border-radius: 10px;  
  border: 2px solid #f1f1f1; 
}

 
::-webkit-scrollbar-thumb:hover {
  background: #555;  
}
