.robotrents {
  padding: 20px;
  background-color: #fff;
}
.robotrents .p-absolute{
  bottom: 20px;
}
.robotrent {
  padding: 20px;
  width: auto;
}
.robotrent_main{
  margin: 20px;
}
.editCardLayout {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.eform_left{
  width: 450px;
}
.eform_left .ant-alert-message{
  color: rgba(102, 77, 3, 1) !important;
  font-weight: 700;
}
.eform_left .image-set-back{
  width: 345px !important;
  height: 294px;
}
.eform_right .label{
  font-family: var(--font-family-head-);
font-size: 16px;
font-weight: 700;
line-height: 16px;
letter-spacing: -0.02em;
text-align: left;
color: var(--primary-color);

}
.eform_right{
background-color: #fff;
height: 90vh;
}
.robot-header {
  border: 1px solid #f3f3f3;
  color: var(--primary-color);
}

.card {
  text-align: center;
  width: 100%;
  height: auto;

}
.robotrents .card{
 background-color: unset;
 box-shadow: unset;
 margin-top: 0;
}
.alignbutton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 32px;
  width: 36%;
}
.robotrents .card .card_main{
  gap: 30px;
  
}
.width_60 {
  width: 60%;
}
.slideImage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #FFFFFF;
  box-shadow: 10px 10px 80px -15px rgba(231, 228, 232, 0.6);
padding: 15px;
}
.fieldalign {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.formcolumn {
  width: 55%;
  padding: 15px 25px;
}
.formcolumn input{
  width: 375px;
  height: 50px;
  border: 1px solid rgba(224, 229, 242, 1) !important;
}
.formcolumn .inputBox-style,
.formcolumn textarea{
  border: 1px solid rgba(224, 229, 242, 1) !important;
  width: 375px;
}
.formcolumn .inputBox-style{
  max-width: unset !important;
}
.title {
  font-weight: 500;
  text-align: center;
  font-family: var(--font-family-head-) !important;
}
.font {
  font-size: 16px;
}
.widthFull {
  width: 100%;
}
.card_main .data-content{
display: flex;
flex-direction: column;
gap: 20px;
}


@media screen and (max-width: 1201px ) {
  .formcolumn,
  .alignbutton{
    width: 100%;
  }
  .robotrents .card .card_main{
    gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
 
}