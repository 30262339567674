.changePasswordContainer {
  display: flex;
  justify-content: center;
  padding: 0;
}
.header-buttons {
  margin-left: 6%;
  padding: 0% 2%;
}
.forgotPasswordContainer {
  display: flex;
  width: 100%;
  height: 91vh;
  color: #262c6f;
  box-sizing: border-box;
  justify-content: flex-end;
}
.steps-div {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.one-button-div-center {
  display: flex;
  width: 100%;
  position: static;
  justify-content: center;
  box-sizing: border-box;
}
.two-button-div {
  display: flex;
  width: 100%;
  position: static;
  justify-content: space-between;
  box-sizing: border-box;
}
.info-text {
  font-size: 12px;
  color: #262c6f;
  box-sizing: border-box;
  padding-bottom: 4%;
}
.button-arrow {
  font-size: 200%;
}
.form-header-user {
  padding-bottom: 4%;
}
.form-header-title {
  font-size: 30%;
}
.form-header-desc {
  font-size: 20%;
}
.spacing {
  margin-top: 5%;
}
.top-spacing {
  margin-top: 2%;
}
.top-middle-body {
  padding: 5% 17% 16% 18%;
}
.email-form-box {
  padding: 9%;
}

.forget_pass .label{
  font-family: var(--font-family-head-);
font-size: 36px;
font-weight: 700;
line-height: 56px;
letter-spacing: -0.02em;
text-align: left;


}
.forget_pass .regular-text{
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #A3AED0 !important;
  margin-bottom: 15px;
}
.ant-steps-item-tail::after {
  height: 3px !important;
  border-radius: 5px !important;
}
.step_btn{
  display: flex;
  flex-direction: row-reverse;
  gap: 15px;
}
.step_btn button{
  margin-top: unset;
}