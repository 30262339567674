.blocklyToolboxDiv {
    background-color: white;
    padding-left: 30px;
}

.blocklyTreeRow {
    height: 35px;
    line-height: 35px;
    margin-bottom: 10px;
    padding-right: 8px;
}

.blocklyMainBackground{
    stroke-width: 0;
    fill: #8080800f !important;
}

.blocklyTreeRoot{
    padding: 0;
}