.login-form {
  padding: 9%;
}

.forgot-password-style {
  color: var(--primary-color);
  cursor: pointer;
  /* float: right; */
  text-align: right;
  padding-bottom: 2%;
  font-weight: 600;
  margin-left: 5px;
}

.login_input {
  border-color: red !important;
}

.text_white {
  color: #f2f2f2 !important;
}

.center-body {
  padding: 9% 17% 16% 18%;
}

.login-box {
  width: 40%;
}
.auth_logo{
  max-width: 100%;
  height: 30px;
  position: absolute;
  top: 30px;
  right: 30px;
}
.footer_form{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding: 0 35px;
}
.footer_form div{
  display: flex;
  align-items: center;
  gap: 15px;
}
.footer_form p{
  font-family: var(--font-family-head-);
font-size: 14px;
font-weight: 700;
line-height: 24px;
letter-spacing: -0.02em;
text-align: center;

}
.form_btn {
  font-family: var(--font-family-head-);
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: center;

}

.login_title p {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;

}

.login_title div {
  font-family: var(--font-family-head-);
  font-size: 36px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #A3AED0;
}

.f_label {
  font-family: var(--font-family-head-);
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  color: #000;
  margin-bottom: 10px;
}

.right-body-login {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #FFFFFF;
  flex: 1;
  position: relative;
}

.login-box .inputBox-style {
  border-radius: 0 !important;
  border-color: #E0E5F2 !important;
}

input::placeholder {
  color: #E0E5F2 !important;
  font-size: 14px;
  font-weight: 600;
}


.login-btn {
  font-weight: 600;
}

.login-btn:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

@media screen and (max-width: 1440px) {
  .login-box{
    width: 75%;
  }

}
@media screen and (max-width: 1200px) {
 
  .right-body-login,
  .payment-parent-div {
    width: 100%;
  }

  .login-box {
    width: 50%;
  }

  .center_div {
    flex-direction: column;
  }

  .side-banner-container {
    display: none;
  }

  .payment-indiv-div {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 1201px) {
  .login_logo {
    display: none;
  }
}