#auto-money-block {
    flex-direction: column;
  }
  
  #auto-money-block .auto-money-field {
    width: 100%;
    display: flex;
  }
  
  #auto-money-block .auto-money-label {
    margin-right: 1em;
    user-select: none;
  }
  
  #auto-money-block .auto-money-icon {
    align-self: center;
  }
  
  #auto-money-block .auto-money-select {
    width: 100%;
    margin-top: -1em;
  }
  
  #auto-money-block .auto-money-form-item {
    margin: 0;
  }