.profile-container {
  /* background: #f2f2f2; */
  /* height: 90vh; */
  padding: 2% 0;
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.silver-button {
  background: transparent linear-gradient(105deg, #9ca7b2 0%, #ededed 100%) 0%
    0% no-repeat padding-box !important;
  border: 0px !important;
  border-radius: 5px;
}
.bronze-button {
  background: transparent linear-gradient(104deg, #804a00 0%, #9c7a3c 100%) 0%
    0% no-repeat padding-box !important;
  border: 0px !important;
  border-radius: 5px;
}

.silver-button:hover,
.bronze-button:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.upgrade {
  /* width: 100%; */
  color: var(--primary-color);
}
.profile_cards {
  background-color: white;
  /* padding: 3%; */
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}




.ant-steps-item-title::after{
  background-color: #EFF0F6;
  height: 5px;
  border-radius: 15px;
}
.stepper_main{
  background: #fff;
  padding: 25px;
  width: 75%;
  height: 850px;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--primary-color);
}
.first_step_card{
  /* padding:0 15px; */
}
.first_step_card h2{
  font-family: var(--font-family-head-);
font-size: 32px;
font-weight: 700;
line-height: 42px;
letter-spacing: -0.02em;
text-align: left;
margin: 0;
}
.first_step_card span{
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
}
.first_step_card .inputField{
  display: flex;
  gap: 20px;
  margin-top: 35px;
}
.first_step_card .calen{
  /* width: 400px; */
  height: 60px;
  padding: 17px 8px;
  background: #CFF4FC;
  margin-top: 25px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.first_step_card .rs_days{
  /* width: 400px; */
  height: 60px;
  padding: 17px 8px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  background: #F8D7DA;
  border: 1px solid rgba(234, 134, 143, 1)

}
.right-part{
  display: flex;
  align-items: center;
  gap: 5px;
}
.left-part{

}
.right-part h6,
.left-part h6{
  font-family: var(--font-family-head-);
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -0.02em;
  text-align: left;
  
}
.left-part input{
  all: unset;
  background: #F1F1F8;
   margin-left: 5px;
}
.right-part input{
  all: unset;
  background: #F1F1F8;
   margin-left: 5px;
}
.left-part .fields{
  width: 255px;
  padding: 10px 5px;
  border: 1px solid #E0E5F2;
  background: #F1F1F8;

}
.right-part .fields{
  width: 255px;
  padding: 10px 5px;
  border: 1px solid #E0E5F2;
  background: #F1F1F8;

}

.left-part .ant-input[disabled] {
background-color:  #F1F1F8;
color: var(--primary-color);
}
.right-part .ant-input[disabled] {
background-color:  #F1F1F8;
color: var(--primary-color);
}