.side-banner-container {
 
  background-color: var(--primary-color);

}
.discription-style {
  font-family: Montserrat;
  font-weight: 400;
  margin-top: 50%;
  color: #ffffff;
  font-size: 25px;
}

.message-style {
  padding-top: 10px;
  color: #e7dff0;
}
.fw {
  font-weight: 600;
}

.ant-input:hover {
  border-color: var(--primary-color);
  border-right-width: 1px !important;
}
.ant-input:focus {
  -webkit-box-shadow: unset;

}
.side_logo{
  max-width:  100%;
  height: 100vh;
}