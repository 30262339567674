.layoutContainer {
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
}
.layoutNavContainer {
  height: 100%;
  position: fixed;

  background-color: #211d70;
  width: 6%;
}
.mainContentContainer {
  width: 100vw;
  
  
}

.contentBody{
  min-height: 91.5vh;
  background: #E6E8EC;
  flex: 1;
}
.ant-steps-item-title{
  font-weight: 600 !important;
}

.ant-btn[disabled] {
  color: var(--textColor)!important;
}
.ant-modal-footer .ant-btn[disabled] {
  color: #fff!important;
}

.sidebar-container{
width: 241px;
border: 1px solid var(--bordercolor);
}
.s_main{
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-top: 30px;
}
.body-toggle{
  display: flex;
}
.title-name{
font-size: 32px;
font-weight: 700;
line-height: 30px;
letter-spacing: -0.02em;
text-align: left;
font-family: var(--font-family-head-);

}
.side_item{
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.side_item p,.s_main a{
font-size: 16px;
font-weight: 500;
line-height: 30px;
letter-spacing: -0.02em;
text-align: left;
color: var(--textColor);
}
.side_item svg{
  width: 24px;
  height: 24px;
  
}
