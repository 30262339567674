.payment-box {
  width: 30%;
  background: white;
  border-radius: 5px;
}

.btn_payment {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.payment-header {
  padding: 20px;
  color: rgb(219, 219, 219);
}

.payment-header-desc {
  padding-bottom: 20px;
  margin-top: 40px;
}

.silver {
  background: transparent linear-gradient(105deg, #9ca7b2 0%, #ededed 100%) 0% 0% no-repeat padding-box !important;
}

.bronze {
  background: transparent linear-gradient(104deg, var(--ternary-color), var(--secondary-color) 100%) 0% 0% no-repeat padding-box !important;
}

.free {
  background: transparent linear-gradient(104deg, #804a00 0%, #d3d2d0 100%) 0% 0% no-repeat padding-box;
}

.payment-body {
  padding: 20px;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-price {
  font-size: 70px;
  font-weight: 100;
}

.payment-footer {
  padding: 0 30px;
}

.payment-parent-div {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 3% 10%;
  /* background-color: #f2f2f2; */
  overflow: auto;
}

.payment-stripe-div {
  /* height: 100vh; */
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 3% 10%;
  /* background-color: #f2f2f2; */
  overflow: auto;
  /* width: 100vw; */
}

.payment-outer-div {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: #f2f2f2; */
  /* gap: 4%; */
  padding: 0 15px;
}

.cross-mark {
  color: red;
}

.payment-indiv-div {
  width: 100%;
  display: flex;
  height: 100vh;
}

.striped-border {
  border: 1px solid #673ab7;
  width: 60%;
  margin: 60px 30px;
}

.striped2-border {
  border: 1px solid rgba(224, 229, 242, 1);
  height: 100px;
  width: 1px;
}

.horizontal-line {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.paypal-div {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.paypals-div {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.payment-div {
  width: 50%;
  height: auto;
}

.div-space {
  width: 50px;
}

.summary-div {
  width: 30%;
  height: auto;
}

.align_Center {
  justify-content: center;
  align-items: flex-start;
  padding-top: 32px;
  padding-bottom: 32px;
}

.pad {
  padding: 16px;
}

.margin_TB {
  margin-top: 16px;
  margin-bottom: 16px;
}

.align_base {
  justify-content: space-between;
  align-items: baseline;
}

.robotPay_Body {
  padding: 30px;
  width: auto;
  background-color: #f3f3f3;
}

.pay_method_body {
  background-color: white;
}

.card-border {
  padding: 16px;
  /* border: whitesmoke 1px solid;
  text-align: left;
  width: 100%;
  height: auto; */
}

.div-height {
  height: 130px;
}

/* plans */

section {
  position: relative;
  -webkit-font-smoothing: antialiased;
}

.pricing {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
}

.pricing-item {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: stretch;
  align-items: stretch;
  text-align: center;
  -webkit-flex: 0 1 330px;
  flex: 0 1 330px;
}

.pricing-action {
  color: inherit;
  border: none;
  background: none;
  cursor: pointer;
}

.pricing-action:focus {
  outline: none;
}

.pricing-feature-list {
  text-align: left;
}

.pricing-palden .pricing-item {
  cursor: default;
  background: #fff;
  box-shadow: 0 0 10px rgba(46, 59, 125, 0.23);
  border-radius: 20px 20px 10px 10px;
  margin: 1em;
}

.resend_code {
  margin: 20px 0 0 0;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (min-width: 66.25em) {
  .pricing-palden .pricing-item {
    margin: 1em -0.5em;
  }

  .pricing-palden .pricing__item--featured {
    margin: 0;
    z-index: 10;
    box-shadow: 0 0 20px rgba(46, 59, 125, 0.23);
  }
}

.pricing-palden .pricing-deco {
  border-radius: 10px 10px 0 0;
  background: var(--primary-color);
  padding: 3em 0 6em;
  position: relative;
}

.pricing-palden .pricing-deco-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
}

.pricing-palden .pricing-title {
  font-size: 0.75em;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #fff;
  font-weight: bolder;
}

.pricing-palden .deco-layer {
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}

.pricing-palden .pricing-item:hover .deco-layer--1 {
  -webkit-transform: translate3d(15px, 0, 0);
  transform: translate3d(15px, 0, 0);
}

.pricing-palden .pricing-item:hover .deco-layer--2 {
  -webkit-transform: translate3d(-15px, 0, 0);
  transform: translate3d(-15px, 0, 0);
}

.pricing-palden .icon {
  font-size: 2.5em;
}

.pricing-palden .pricing-price {
  font-size: 5em;
  font-weight: bold;
  padding: 0;
  color: #fff;
  margin: 0 0 0.25em 0;
  line-height: 0.75;
}

.pricing-palden .pricing-currency {
  font-size: 0.15em;
  vertical-align: top;
}

.pricing-palden .pricing-period {
  font-size: 0.15em;
  padding: 0 0 0 0.5em;
  font-style: italic;
}

.pricing-palden .pricing__sentence {
  font-weight: bold;
  margin: 0 0 1em 0;
  padding: 0 0 0.5em;
}

.pricing-palden .pricing-feature-list {
  margin: 0;
  padding: 0.25em 0.5em 2.5em;
  list-style: none;
  text-align: center;
}

.pricing-palden .pricing-feature {
  padding: 1em 0;
}

.pricing-palden .pricing-action {
  font-weight: bold;
  padding: 1em 2em;
  color: #fff;
  border-radius: 30px;
  background: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.pricing-palden .pricing-action:hover {
  font-weight: bold;
  padding: 1em 2em;
  color: var(--primary-color);
  border-radius: 30px;
  background: unset !important;
  border: 1px solid var(--primary-color);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.pricing-palden .pricing-action:hover,
.pricing-palden .pricing-action:focus {
  background-color: #100a13;
}

.pricing-palden .pricing-item--featured .pricing-deco {
  padding: 5em 0 8.885em 0;
}

.stripe-btn {
  background-image: linear-gradient(rgb(125, 197, 238),
      rgb(0, 140, 221) 85%,
      rgb(48, 162, 228));
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  position: relative;
  padding: 0px 12px;
  display: block;
  height: 30px;
  line-height: 30px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  box-shadow: rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  text-shadow: rgba(0, 0, 0, 0.25) 0px -1px 0px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.padding_btn {
  padding: 5px 20px !important;
}

.padding_or {
  padding: 0;
  margin: 5px 0 !important;
}

.coupon_verify {
  padding: 4px 6px;
  width: 160px;
  /* margin: auto; */
  font-size: 15px;
  font-weight: bold;
  background: var(--secondary-color);
  color: white;
  /* border-radius: 15px; */
  cursor: pointer;
  width: 200px;
}

.pay_robot {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 20px;
}

.right_card,
.left_card {
  flex: 1;
  height: 625px;
  top: 120px;
  left: 270px;
  gap: 0px;
  opacity: 0px;
  background: #fff;
  padding: 20px;
}

.right_card h3,
.left_card h3 {
  font-family: var(--font-family-head-);
  font-size: 24px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-align: left;

}

.pay_des {
  font-family: var(--font-family-head-);
  font-size: 14px;
  font-weight: 400;
  line-height: 17.05px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #404E6A;

}

hr {
  border: 1px solid #E6EFF5;
}

.calcul {
  padding: 20px 0;
  border-top: 1px solid #E6EFF5;
  border-bottom: 1px solid #E6EFF5;

}

.calcul h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24.36px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #404E6A;
}

.calcul h6 {
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: right;
}

.calcul h6 sup {
  margin-right: -5px;
}

.calcul h6 span {
  font-size: 15px;
}

.robot_payment {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
  height: 85%;
}

.card-border .StripeCheckout {
  background: transparent !important;
  box-shadow: unset !important;
  height: 55px;
  width: 250px;
  border: 1px solid var(--secondary-color) !important;
}

.card-border .StripeCheckout span {
  background: transparent !important;
  box-shadow: unset !important;
  color: var(--secondary-color) !important;
  font-size: 20px !important;
  font-style: italic !important;
}



/* new card  */
.paymeny_card_main{
  display: flex;
  justify-content: space-around;
  padding:  0.5rem;
  background: #fff;
}
.pay_card_main {
  background: #E6E8EC;
  width: 365px;
  height: 700px;
  position: relative;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
}
.pay_card_main img{
width: 100%;
}

.bottom_card {
  padding: 30px 50px;
}

.card_price {
  font-family: var(--font-family-head-);
  font-size: 35px;
  color: var(--primary-color);
}

.card_price span {
  font-size: 20px;
}

.card_details {
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
  color: var(--primary-color);
}

.pay_btn_card {
  position: absolute;
  bottom: 35px;
  width: 70%;
  text-align: center;
}

.pay_btn_card button {
  width: 200px;
  height: 50px;
  background: var(--primary-color);
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  border: unset;
}



.animate-charcter {
  background-image: linear-gradient(-225deg,
      #231557 0%,
      var(--secondary-color) 29%,
      var(--primary-color) 67%,
      var(--ternary-color) 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 15px;
  padding: 8px;
  font-weight: 600;
  font-family: var(--font-family-head-);
  cursor: pointer;
  
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.promo h1{
  color: var(--secondary-color);
  font-family: var(--font-family-head-);
  margin: 0;

}
.promo h3{
  text-align: justify !important;
  padding: 5px 15%;
  margin: 0;

}
.promo h1 img{
  height: 60px;
  margin-right: 15px;
  margin-bottom: 15px;
}
.promo button{
  background-color: var(--ternary-color) !important;
  color: var(--primary-color);
  margin-top: 15px;
}
.promo button:hover{
  color: var(--primary-color) !important;
}
.promo ul{
  color: var(--primary-color);
  font-family: var(--font-family-head-);
  margin: 0;
  text-align: justify;
  padding: 5px 20%;
}
.promo ul span{
 color: var(--secondary-color);
}