.nav-container {
  justify-content: space-between;
  /* padding: 15px 30px; */
  flex-direction: row;
  display: flex;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}


.setting {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo-sidebar {
  padding: 20px 30px;
  border-right: 1px solid #E6EFF5;
  /* border-bottom: 1px solid #E6EFF5; */
}

.ph {
  padding: 0 25px 0 15px;
}

.logout-logo {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.nav_item {
  color: #404E6A;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}



.logo {
  width: 180px;
  height: 54px;
  cursor: pointer;
}

.silver-circle {
  border: 2px solid rgb(199, 210, 221);
  padding: 2px;
}

.bronze-circle {
  border: 2px solid rgb(182, 108, 5);
  padding: 2px;
}

.magic-no {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.notification {
  position: absolute !important;
  top: 0 !important;
  right: 0px;
  border: 1px solid #80808026;
  z-index: 100;
  height: 89vh;
  flex-wrap: wrap;
  padding: 0px !important;
}

.li-item {
  overflow-wrap: normal;
  border-bottom: 1px solid #80808026 !important;
  border-radius: 5px !important;
  padding: 0px !important;
}

.cancel-button {
  object-fit: scale-down;
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.notification-header {
  background: #211d70;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  cursor: default;
}

.notification-body {
  overflow-y: auto;
  height: 89vh;
  cursor: default;
}

.notification-clear {
  margin-left: 20%;
  cursor: pointer;
}

.nav-items {
  color: #262c6f;
  margin-right: 20px;
  margin-left: 20px;
}

.card-not-body {
  display: flex;
  flex-direction: column;
}

.card-not-header {
  display: flex;
  justify-content: space-between;
}

.header-text-not {
  font-weight: bold;
  font-size: 16px;
  width: 90%;
}

.notification-text {
  color: #8c8c8c;
  font-size: 13px;
}

.time-not {
  color: #bfbfbf;
  font-size: small;
  display: flex;
  justify-content: flex-start;
  padding-top: 2%;
  font-weight: lighter;
}

#navbar-field .drawer-bottom {
  position: absolute;
  bottom: 0;
}

.nav-align {
  display: "flex";
  flex-wrap: "wrap";
}

.margin-top-10 {
  margin-top: 10px;
}

.nav_btn:hover {
  background: transparent !important;
  color: #57387d;
}

.sidebar_btn {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.d-flex-between {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.flex-item {
  flex: 1;
}

.avtar-logo {
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;
}

.save-div {
  gap: 5px;
  width: 450px;
}

.remaining {
  color: white;
  background-color: var(--primary-color);
  max-width: 100%;
  height: 50px;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  gap: 15px;

}

.r_days {
  font-weight: 700;
  font-size: 18px;
  color: var(--primary-color);
  background-color: white;
  border-radius: 50px;
  padding: 3px 10px;
}

.r_label {
  font-size: 16px;
  font-weight: 700;
}


.storeIcon path {
  stroke: #404E6A;
}

.storeIconActive path {
  stroke: var(--primary-color);
}
p{
  margin: 0 !important;
}
.nav_item p {
  font-size: 14px;
  font-weight: 400;
  color: #404E6A;
  margin-top: 6px !important;
}

.nav-active-p{
  color: var(--primary-color) !important;
  font-weight: 700 !important;
}
.nav_btns{
  gap: 20px;
}
.view_more{
  display: flex;
}
@media (max-width: 1024px) {
  .nav_btns {
    display: none;
  }

  .d-flex-between {
    justify-content: flex-end !important;
  }
}

@media (min-width: 1025px) {
  .sidebar_icon {
    display: none;
  }
}

.custom-dropdown .ant-dropdown-menu {
  position: relative;
}

.custom-dropdown .ant-dropdown-menu::before {
  content: '';
  position: absolute;
  top: -10px; /* Adjust this value to move the tail up or down */
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent var(--primary-color) transparent; /* Adjust the color to match your dropdown background */
  z-index: 1;
}

.custom-dropdown .ant-dropdown-menu::after {
  content: '';
  position: absolute;
  top: -9px; /* Adjust this value to move the tail up or down */
  left: 50%;
  transform: translateX(-50%);
  border-width: 9px;
  border-style: solid;
  border-color: transparent transparent var(--primary-color) transparent; /* Adjust the color to match your dropdown border */
  z-index: 0;
}
